import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (

    <Layout location={location} title={siteTitle} className="bg-black">
      <Seo title="404: Not Found" />
      <div className="bg-black py-40 text-center">
        <div className="sm:pt-24 sm:pb-40 lg:pt-20 lg:pb-28">
          <div className="w-full px-4 sm:px-6">
            <div className="max-w-screen-xl mx-auto">
              <h1 className="tracking-tight font-medium text-white sm:tracking-tight text-8xl lg:text-10xl knockoutFont uppercase">404: Not Found</h1>
              <p className="mt-4 text-xl text-white">You just hit a route that doesn&#39;t exist... the sadness.</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
